import styled from 'styled-components';

import { InputAdornment } from '@mui/material';

import { Breakpoints } from '@src/styles/breakpoints';

import { StyledInput } from '../FormComponent/styles';

export const StyledContainer = styled.div`
  position: relative;
`;

export const StyledDateRange = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 6;
  top: 50px;
  .rdrDayToday .rdrDayNumber span:after {
    background: ${props => props.theme.primaryColor.royalBlue};
  }
  ${Breakpoints.MOBILE} {
    width: 100%;
    justify-content: left;
    .rdrCalendarWrapper {
      width: -webkit-fill-available;
    }
    .rdrMonth {
      width: auto;
    }
  }
`;

export const StyledInputAdornment = styled(InputAdornment)`
  position: absolute;
  right: 5%;
  bottom: 45%;
  cursor: pointer;
`;

export const StyledDateInput = styled(StyledInput)`
  caret-color: transparent;
`;

export const StyledCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0.5rem 0;
`;

export const StyledCheckboxInput = styled.input`
  margin-top: 4px;

  &:checked {
    background-color: ${({ theme }) => theme.primaryColor.shadePurple};
    border-color: ${({ theme }) => theme.primaryColor.shadePurple};
  }
`;

export const StyledCheckboxLabel = styled.label`
  font-size: 14px;
  color: ${({ theme }) => theme.primaryColor.shadePurple};
`;
