import { stepIconClasses } from '@mui/material/StepIcon';
import { createTheme } from '@mui/material/styles';

import { Breakpoints } from './breakpoints';
import { theme as styledTheme } from './theme';

const theme = createTheme({
  typography: {
    fontFamily: ['Lato', 'the-seasons'].join(','),
  },
  palette: {
    primary: {
      main: `${styledTheme.primaryColor.royalBlue}`,
    },
    secondary: {
      main: `${styledTheme.primaryColor.aquaBlue}`,
    },
  },
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: 'Lato',
          fontWeight: 400,
          fontSize: '15px',
          lineHeight: '19px',
          letterSpacing: '-0.01em',
          color: '#09142F',
          '&:hover': {
            background: '#F6F7FA',
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          marginLeft: '25px',
          marginTop: '8px',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        icon: {
          '& path': {
            fill: styledTheme.primaryColor.black,
            d: 'path("M8.33333 15H11.6667V13.3333H8.33333V15ZM2.5 5V6.66667H17.5V5H2.5ZM5 10.8333H15V9.16667H5V10.8333Z")',
          },
        },
      },
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          fontWeight: '700',
          fontSize: '16px',
          lineHeight: '20.8px',
          color: styledTheme.primaryColor.twilightBlue,
          backgroundColor: styledTheme.primaryColor.skyBlue,
        },
        sortAction: {
          display: 'flex',
          alignItems: 'center',
          fontWeight: '700',
          fontSize: '16px',
          lineHeight: '20.8px',
          color: styledTheme.primaryColor.twilightBlue,
          backgroundColor: styledTheme.primaryColor.skyBlue,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          '&.MuiPaper-elevation4': {
            boxShadow: 'none',
            borderRadius: '15px',
          },
          '& > div:nth-child(2)': {
            border: `1px solid ${styledTheme.primaryColor.lunarElegance}`,
            borderRadius: '20px',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '15px 20px',
          fontSize: '14px',
          fontWeight: '500',
          lineHeight: '18.2px',
          color: styledTheme.primaryColor.twilightBlue,
          fontFamily: styledTheme.fontFamily.lato,
          boxShadow: 'none',
          [`${Breakpoints.TABLET}`]: {
            borderBottom: 'none',
          },
        },
      },
    },
    MUIDataTableBodyRow: {
      styleOverrides: {
        root: {
          '&:hover': {
            cursor: 'pointer',
          },
          [`${Breakpoints.TABLET}`]: {
            '&:nth-of-type(odd)': {
              backgroundColor: `${styledTheme.primaryColor.GlacierMist}`,
            },
            '&:nth-of-type(even)': {
              backgroundColor: `${styledTheme.primaryColor.white}`,
            },
          },
        },
      },
    },
    MUIDataTableBodyCell: {
      styleOverrides: {
        stackedCommon: {
          [`${Breakpoints.TABLET}`]: {
            '&:nth-last-of-type(2)': {
              fontWeight: 600,
            },
          },
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          width: 36,
          height: 36,
          [`&.${stepIconClasses.completed}`]: {
            borderRadius: '50%',
            cursor: 'pointer',
            color: `${styledTheme.primaryColor.shadePurple}`,
          },
          [`&.${stepIconClasses.active}`]: {
            borderRadius: '50%',
            color: `${styledTheme.primaryColor.royalBlue}`,
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontFamily: `${styledTheme.fontFamily.lato}, ${styledTheme.fontFamily.seasons}`,
          fontWeight: 600,
          color: `${styledTheme.primaryColor.duskHarbor}`,
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          [`${Breakpoints.MOBILE}`]: {
            padding: 0,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          [`${Breakpoints.MOBILE}`]: {
            marginRight: '8px !important',
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        toolbar: {
          [`${Breakpoints.MOBILE}`]: {
            padding: 0,
          },
        },
        select: {
          [`${Breakpoints.MOBILE}`]: {
            padding: 0,
          },
        },
        actions: {
          [`${Breakpoints.MOBILE}`]: {
            marginLeft: 0,
          },
        },
      },
    },
  },
});

export { theme };
