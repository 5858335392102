import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { PersistGate } from 'redux-persist/integration/react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { persistor, store } from './redux/store';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './index.css';

const queryClient = new QueryClient();

posthog.init('phc_gUpdhbW8nmsqVG7TaFDsBQEkaqOTy0IAE8b7YZNL6Q0', {
  api_host: 'https://us.i.posthog.com',
  person_profiles: 'always', // or 'always' to create profiles for anonymous users as well
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <PostHogProvider client={posthog}>
            <App />
          </PostHogProvider>
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
