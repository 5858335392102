import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation, useParams, useSearchParams } from 'react-router-dom';

import Footer from '@src/components/Footer';
import Header from '@src/components/Header';
import { pageTitleMap, UiRoutes } from '@src/lib/constants';
import { routesConfigType } from '@src/lib/types';
import { ReduxProps } from '@src/redux/type';
import { StyledMainWrapper } from '@src/styles/mainLayoutWrapper';

// This is a placeholder function for your actual authentication check

const PrivateRoute = ({ config }: { config: routesConfigType }) => {
  const buttonParam = useSearchParams()[0].get('button');
  const { id: searchId } = useParams();
  const location = useLocation();

  // Get the current path from the location object
  const currentPath = location.pathname;

  useEffect(() => {
    if (pageTitleMap['/' + currentPath.split('/')[1]]) {
      document.title = pageTitleMap['/' + currentPath.split('/')[1]];
    }
  }, [location.pathname]);

  const { component: Component, styles = true, isFooter, bgColor, isHeaderSticky } = config;
  const { isUserAuthenticated } = useSelector((state: ReduxProps) => state?.loginReducer?.loginState);

  if (!isUserAuthenticated) {
    if (buttonParam) {
      return <Navigate to={`${UiRoutes.LOGIN}?redirect=${searchId}&button=true`} replace />;
    }

    return <Navigate to={UiRoutes.PACKAGESFS} replace />;
    // return <Navigate to={UiRoutes.HOME} replace />;
  }

  return styles ? (
    <StyledMainWrapper $bgColor={bgColor}>
      <Header isSticky={isHeaderSticky} isLoggedIn={true} />
      <Component />
      {isFooter && <Footer />}
    </StyledMainWrapper>
  ) : (
    <Component />
  );
};

export default PrivateRoute;
