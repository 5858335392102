import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

import { Breakpoints } from '@src/styles/breakpoints';

const FlexBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const StyledFooterContainer = styled.div`
  background: ${props => props.theme.primaryColor.deepSeaBlue};
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 3.75em calc(${({ theme }) => theme.space.get('2xl')}*3.1);
  ${Breakpoints.DESKTOP} {
    padding: 3.75em calc(${({ theme }) => theme.space.get('2xl')}*2);
  }
  ${Breakpoints.DESKTOP_SMALL} {
    padding: 3.75em calc(${({ theme }) => theme.space.get('s')});
  }
  position: relative;
  ${Breakpoints.TABLET} {
    padding: 13% 3%;
  }
`;

export const StyledTextWithIconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const StyledElementOuter = styled.div`
  display: flex;
  gap: 30px;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  ${Breakpoints.DESKTOP} {
    flex-wrap: wrap;
  }
`;

export const StyledContactContainer = styled(FlexBox)`
  color: ${props => props.theme.primaryColor.white};
  max-width: 290px;
  gap: 14px;
`;

export const StyledText = styled.div<{ $color?: string }>`
  display: flex;
  align-items: center;
  color: ${props => (props.$color ? props.$color : props.theme.primaryColor.white)};
  font-size: 18px;
  font-weight: 500;
  ${props => props.onClick && 'cursor:pointer;'}
  line-height: 180%;
  gap: 12px;
  padding: 8px 2px;
`;

export const StyledTextIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledSmallText = styled(StyledText)`
  font-size: ${props => props.theme.fontSize.get('2xs')};
  font-weight: 500;
`;

export const StyledHR = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.primaryColor.white};
  opacity: 0.2;
  align-self: center;
`;

export const StyledContentContainer = styled(FlexBox)`
  color: ${props => props.theme.primaryColor.white};
  gap: 10px;
`;

export const StyledHeading = styled.div<{ dotIcon?: boolean }>`
  color: ${props => props.theme.primaryColor.brightCyan};
  font-size: 22px;
  font-weight: 700;
  line-height: 130%;
  padding: ${props => (props.dotIcon ? '10px 24px' : '10px 0px')};
`;

export const StyledLogosContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
`;

export const StyledBlastLogo = styled.img`
  width: fit-content;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  ${({ to }) => !to && 'cursor: default;'}
`;
