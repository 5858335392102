import { Booking, Event } from '@src/model/Event';

import actionConstants from '../constants';

const initialState: Event = {
  startDate: '',
  endDate: '',
  emirate: '',
  occasion: '',
  numberOfPerson: 0,
  customerId: '',
  companyId: '',
  makeMyOwn: false,
  bookedBy: { _id: '', firstName: '', lastName: '', role: 'user' },
  updatedBy: '',
  _id: '',
  bookedAt: '',
  updatedAt: '',
  paymentRequired: true,
  orderId: undefined,
  orderNumber: '',
  contactDetails: undefined,
  deliveryAddress: {
    address: '',
    apartmentDetail: '',
    emirate: '',
  },
  packageDetails: {
    packageId: '',
    name: '',
    service: 'buffet',
    type: 'breakfast',
  },
  Bookings: [
    {
      orderId: '',
      eventId: '',
      bookingStatus: {
        id: '',
        name: '',
      },
      numberOfPerson: 0,
      _id: '',
      totalAmount: 0,
      deliveryAddress: {
        address: '',
        apartmentDetail: '',
        emirate: '',
      },
      bookingDate: '',
      bookingTime: '',
      packageDetails: {
        packageId: {
          _id: '',
          picture: {
            name: '',
            type: '',
            url: '',
          },
        },
        name: '',
        price: 0,
        service: 'buffet',
        type: '',
      },
      QR: {
        name: '',
        url: '',
        type: '',
      },
      dishes: [
        {
          dishId: '',
          sapId: '',
          name: '',
          quantity: 0,
          category: { name: '', _id: '' },
          subCategory: '',
          price: 0,
          addOn: false,
          QR: {
            name: '',
            url: '',
            type: '',
          },
        },
      ],
      addOns: [
        {
          addOnId: '',
          sapId: '',
          name: '',
          quantity: 0,
          category: { name: '', _id: '' },
          subCategory: { name: '', _id: '' },
          price: 0,
          QR: {
            name: '',
            url: '',
            type: '',
          },
        },
      ],
      totalFeedbacks: 0,
      averageRating: 0,
    },
  ],
  comment: undefined,
  eventStatus: undefined,
  orderStatus: undefined,
  eventCategories: undefined,
  isClosed: true,
  totalFeedbacks: 0,
  averageRating: 0,
  overridePricing: false,
  overridenAmount: 0,
  perPersonCost: 0,
};

export default function eventDetailsReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionConstants.CREATE_EVENT_DETAILS:
      return {
        ...state,
        ...action?.payload,
      };
    case actionConstants.UPDATE_EVENT_DETAILS:
      return {
        ...state,
        ...action?.payload,
      };
    case actionConstants.ADD_MORE_BOOKING:
      return {
        ...state,
        Bookings: [...(state?.Bookings as Booking[]), action?.payload],
      };
    case actionConstants.DELETE_BOOKING:
      return {
        ...state,
        Bookings: [...(action?.payload as Booking[])],
      };
    case actionConstants.RESET_BOOKING:
      return {
        ...state,
        ...initialState,
      };

    case actionConstants.SHOW_MESSAGE:
      return {
        ...state,
        isClosed: !state.isClosed,
      };

    case actionConstants.CLEAR_EVENT_DETAILS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
