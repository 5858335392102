import { Suspense, useCallback, useEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { ThemeProvider } from 'styled-components';

import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';

import Loader from '@src/components/Loader';
import PrivateRoute from '@src/layouts/PrivateRoute';
import PublicRoute from '@src/layouts/PublicRoute';
import { checkPermissions } from '@src/lib/helper';
import { permissionEnum, routesConfigType } from '@src/lib/types';
import routesConfig from '@src/routes/routesConfig';

import { setSnackbar } from './api/axios';
import { UiRoutes } from './lib/constants';
import { ReduxProps } from './redux/type';
import { theme as customTheme } from './styles/muiTheme';
import { theme } from './styles/theme';

const App = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loaderState = useSelector((state: ReduxProps) => state?.loaderReducer);

  useEffect(() => {
    setSnackbar(enqueueSnackbar);
  }, [enqueueSnackbar]);

  useEffect(() => {
    if (loaderState && Array.isArray(loaderState.actionsLoading) && loaderState.actionsLoading.length && !isLoading) {
      setIsLoading(true);
    } else if (loaderState && Array.isArray(loaderState.actionsLoading) && loaderState.actionsLoading.length === 0) {
      setIsLoading(false);
    }
  }, [loaderState]);

  const checkPermissionMethod = useCallback(
    (permissions: Array<permissionEnum>): boolean => {
      return permissions.some(permissionName => checkPermissions(permissionName));
    },
    [JSON.stringify(['Create_user'])]
  );

  const renderLayout = (config: routesConfigType, key: string): JSX.Element => {
    switch (config.layout) {
      case 'private':
        return <Route key={key} path={config.path} element={<PrivateRoute config={config} />} />;
      case 'public':
        return <Route key={key} path={config.path} element={<PublicRoute config={config} />} />;
      default:
        return <Route key={key} path={config.path} element={<PrivateRoute config={config} />} />;
    }
  };

  const renderRoute = (config: routesConfigType): JSX.Element | undefined => {
    const routeKey = config.path; // Use a unique identifier if available, or fallback to the path

    if (config.allowedPermission) {
      if (checkPermissionMethod(config.allowedPermission)) {
        return renderLayout(config, routeKey);
      }

      return undefined;
    } else {
      return renderLayout(config, routeKey);
    }
  };

  return (
    <>
      <Suspense fallback={<Loader />}>
        <MUIThemeProvider theme={customTheme}>
          <ThemeProvider theme={theme}>
            <HelmetProvider>
              <Router>
                <Routes>
                  {routesConfig().map(config => renderRoute(config))}
                  {/* <Route path='/' element={<Navigate to={UiRoutes.HOME} replace />} />
                  <Route path='*' element={<Navigate to={UiRoutes.HOME} replace />} /> */}
                  <Route path='/' element={<Navigate to={UiRoutes.PACKAGESFS} replace />} />
                  <Route path='*' element={<Navigate to={UiRoutes.PACKAGESFS} replace />} />
                </Routes>
              </Router>
            </HelmetProvider>
          </ThemeProvider>
        </MUIThemeProvider>
      </Suspense>
      {isLoading && <Loader />}
    </>
  );
};

const AppWithSnackbar: React.FC = () => (
  <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
    <App />
  </SnackbarProvider>
);

export default AppWithSnackbar;
