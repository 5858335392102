import { AxiosError } from 'axios';

import { FooterDataProps } from '@src/components/Footer/types';
import { theme } from '@src/styles/theme';

import {
  amexLogo,
  amexLogoWhite,
  callIcon,
  choosePackage,
  clientAud,
  clientBCG,
  clientBoehringer,
  clientDeliveroo,
  clientDevex,
  clientMuseum,
  clientPepsiCo,
  clientPVH,
  clientPWC,
  clientSnapchat,
  customizeMenu,
  dishDC1,
  dishDC2,
  dishDC3,
  dishDC4,
  dishDC5,
  dishDC6,
  dishFS1,
  dishFS2,
  dishFS3,
  dishFS4,
  dishFS5,
  dishFS6,
  dishFS7,
  dishFS8,
  dishFS9,
  dishFS10,
  dishFS11,
  dishFS12,
  dropOffImg,
  fullBuffetImg,
  locationIcon,
  mastercardLogo1,
  mastercardLogoWhite,
  printerIcon,
  secureCheckout,
  visaLogo1,
  visaLogoWhite,
} from './imgUrl';
import { paymentIconsProps } from './types';

//@TODO routes should be in different file not in constant
const UiRoutes = {
  LOGIN: '/login',
  // HOME: '/home',
  STEPPERS: '/steppers',
  PACKAGESFS: '/full-service-buffet-catering',
  PACKAGESDC: '/drop-off-catering',
  MENU: '/menu',
  THANKYOU: '/thankyou/:id',
  DELIVERY: '/delivery/:id',
  ORDER_DETAILS: '/order-details/:eventId',
  CHOOSE_MEAL: '/choose-meal',
  ORDER_SUMMARY: '/order-summary/:id',
  PAYMENT: '/payment/:id',
  PROFILE: '/profile',
  EDIT_PROFILE: '/edit-profile',
  ORDER_HISTORY: '/order-history',
  RATE_US: '/rate-us/:id',
  QR_CODE: '/rate-us/qr-code/:id',
  MENU_OF_THE_DAY: '/rate-us/menu-of-the-day/:id',
  DISH_DETAILS: '/dish-details/:id',
  ADDONS_DETAILS: '/addons-details/:id',
  EVENT_FEEDBACK: '/feedback/event/:eventId',
};

export const pageTitleMap = {
  [UiRoutes.PACKAGESFS]: 'Full Service Buffet Catering Dubai – Blast Catering',
  [UiRoutes.PACKAGESDC]: 'Best Drop-Off Catering Dubai – Blast Catering',
  [UiRoutes.ORDER_DETAILS.replace('/:eventId', '')]: 'Order Details | Blast Catering',
  [UiRoutes.DELIVERY.replace('/:id', '')]: 'Delivery Details | Blast Catering',
  [UiRoutes.ORDER_SUMMARY.replace('/:id', '')]: 'Order Summary | Blast Catering',
  [UiRoutes.PAYMENT.replace('/:id', '')]: 'Payment | Blast Catering',
  [UiRoutes.THANKYOU.replace('/:id', '')]: 'Thank you | Blast Catering',
  [UiRoutes.LOGIN]: 'Login | Blast Catering',
  [UiRoutes.RATE_US.replace('/:id', '')]: 'Todays Menu | Blast Catering',
};

const validEmail = /^[\w]+(\.[\w]+)*(\+[\w]+)?@([\w-]+\.)+[a-zA-Z]{2,7}$/;
const uaePhoneNumberRegex = /^\+?[0-9]+$/;
const validCVVRegex = /^[0-9]{3,4}$/;
const validNameRegex = /^[a-zA-Z][a-zA-Z0-9 ]*$/;

const statusCodes = {
  API_SUCCESS_CODE: 200,
  API_CREATE_SUCCESS_CODE: 201,
  API_ERROR_CODE: 404,
};

export const WORDPRESS_BASE_URL = process.env.REACT_APP_WORDPRESS_URL;

export const wordpressRoutes = {
  COLLABORATIONS: '/collaborations',
  ABOUT_US: '/about-us',
  BLOGS: '/blog-and-article',
  CONTACT_US: '/contact-us',
  FULL_SERVICE_CATERING: '/full-service-catering',
  DELIVERED_CATERING: '/delivered-catering',
  MENU: '/menu',
  PARTNERS: '/partners',
  EDITORIAL: '/editorial',
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
};

const tabs = [
  { text: 'Home', link: WORDPRESS_BASE_URL },
  { text: 'Full Service Buffet Catering', link: UiRoutes?.PACKAGESFS },
  { text: 'Drop-off Catering', link: UiRoutes?.PACKAGESDC },
  { text: 'Menu', link: WORDPRESS_BASE_URL + wordpressRoutes.MENU },
];

const loggedInHeaderTabs = [
  {
    text: 'Order History',
    link: '/order-history',
  },
  {
    text: 'Profile',
    link: '/profile',
  },
];

const moreItems = [
  { text: 'Partners', link: WORDPRESS_BASE_URL + wordpressRoutes.PARTNERS },
  { text: 'Editorial', link: WORDPRESS_BASE_URL + wordpressRoutes.EDITORIAL },
  { text: 'About Us', link: WORDPRESS_BASE_URL + wordpressRoutes.ABOUT_US },
  { text: 'Get In Touch', link: WORDPRESS_BASE_URL + wordpressRoutes.CONTACT_US },
];

const serviceType = (minimumCounts: (number | null)[]) => [
  {
    id: 'buffet',
    name: 'Full Service Buffet Catering',
    content:
      'All-inclusive buffet catering, where our expert staff comes fully equipped to guarantee an impressive dining experience',
    icon: fullBuffetImg,
    label: 'Minimum person',
    minimumCount: minimumCounts[0],
  },
  {
    id: 'drop-off',
    name: 'Drop-off Catering',
    content: 'Convenient boxed meal service, delivering healthy and delicious food wherever you may be.',
    icon: dropOffImg,
    label: 'Minimum person',
    minimumCount: minimumCounts[1],
  },
];

export const NO_DATA_MESSAGE = 'Sorry but there is no data try to create new';
export const NO_RESULTS_MATCH = 'No matches found. Please refine your search criteria.';
export const NO_FILTER_RESULTS_MATCH = 'No results found. Please adjust your filter criteria.';

export const steps = [
  { name: 'Order Details', link: UiRoutes.ORDER_DETAILS },
  { name: 'Delivery', link: UiRoutes.DELIVERY },
  { name: 'Overview', link: UiRoutes.ORDER_SUMMARY },
  { name: 'Payment', link: UiRoutes.PAYMENT },
  { name: 'Confirmation' },
];

export const occasionData = [
  {
    label: 'Office Party',
    value: 'Office Party',
  },
  {
    label: 'Business Event',
    value: 'Business Event',
  },
  {
    label: 'WorkShop / Seminar',
    value: 'WorkShop / Seminar',
  },
  {
    label: 'Lunch/Dinner Meeting',
    value: 'Lunch Meeting',
  },
  {
    label: 'Crew Catering',
    value: 'Crew Catering',
  },
  {
    label: 'Private Party',
    value: 'Private Party',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export const emiratesData = [
  {
    label: 'Abu Dhabi',
    value: 'Abu Dhabi',
  },
  {
    label: 'Dubai',
    value: 'Dubai',
  },
  {
    label: 'Sharjah',
    value: 'Sharjah',
  },
  {
    label: 'Ajman',
    value: 'Ajman',
  },
  {
    label: 'Fujairah',
    value: 'Fujairah',
  },
  {
    label: 'Ras Al Khaimah',
    value: 'Ras Al Khaimah',
  },
  {
    label: 'Umm Al Quwain',
    value: 'Umm Al Quwain',
  },
];

export const paymentIcons: paymentIconsProps = {
  logos: [
    { logo: mastercardLogo1, height: '24', alt: 'mastercard logo' },
    { logo: visaLogo1, height: '24', alt: 'visa logo' },
    { logo: amexLogo, height: '24', alt: 'amex logo' },
  ],
};

const clientLogosHeight = '32px';

export const clientLogos = [
  { logo: clientAud, height: clientLogosHeight, alt: 'AUD Logo' },
  { logo: clientBCG, height: clientLogosHeight, alt: 'BCG Logo' },
  { logo: clientBoehringer, height: clientLogosHeight, alt: 'Boehringer Logo' },
  { logo: clientDeliveroo, height: clientLogosHeight, alt: 'Deliveroo Logo' },
  { logo: clientDevex, height: clientLogosHeight, alt: 'Devex Logo' },
  { logo: clientPWC, height: clientLogosHeight, alt: 'PWC Logo' },
  { logo: clientMuseum, height: clientLogosHeight, alt: 'Museum Logo' },
  { logo: clientPVH, height: clientLogosHeight, alt: 'PVH Logo' },
  { logo: clientPepsiCo, height: clientLogosHeight, alt: 'PepsiCo Logo' },
  { logo: clientSnapchat, height: clientLogosHeight, alt: 'Snapchat Logo' },
];

export const footerData: FooterDataProps = {
  contactInfo: [
    {
      text: 'Warehouse 2, Building - 33 22nd St - Al Quoz - Al Quoz Industrial Area 3 - Dubai',
      icon: { url: locationIcon, alt: 'location icon' },
    },
    { text: '+97 1 50 663 2853', icon: { url: callIcon, alt: 'phone icon' } },
    { text: 'hello@blastcatering.com', icon: { url: printerIcon, alt: 'email icon' } },
    // { text: '@blastcatering', icon: { url: printerIcon, alt: 'email icon' } },
    // TODO: waiting for instagram icon
  ],
  footerElements: [
    {
      heading: 'Emirates',
      leftElements: [{ text: 'Abu Dhabi' }, { text: 'Sharjah' }, { text: 'Fujairah' }, { text: 'Umm Al Quwain' }],
      rightElements: [{ text: 'Dubai' }, { text: 'Ajman' }, { text: 'Ras Al Khaimah' }],
    },
    {
      heading: 'About',
      leftElements: [
        { text: 'Partners', link: WORDPRESS_BASE_URL + wordpressRoutes.PARTNERS },
        { text: 'Editorial', link: WORDPRESS_BASE_URL + wordpressRoutes.EDITORIAL },
        { text: 'About US', link: WORDPRESS_BASE_URL + wordpressRoutes.ABOUT_US },
        { text: 'Get in Touch', link: WORDPRESS_BASE_URL + wordpressRoutes.CONTACT_US },
      ],
    },
    {
      heading: 'Services',
      leftElements: [
        { text: 'Full Service Buffet Catering', link: UiRoutes?.PACKAGESFS },
        { text: 'Drop-off Catering', link: UiRoutes?.PACKAGESDC },
      ],
    },
  ],
  sectionTwoData: [
    { text: '© 2024 Blast Catering. All rights reserved' },
    {
      isMulti: true,
      color: theme.primaryColor.brightCyan,
      content: [
        {
          text: 'Owned and operated by Dish Catering & Events LLC.',
          link: 'https://www.dish.ae/',
        },
      ],
    },
    {
      isMulti: true,
      content: [
        { text: 'Privacy Policy', link: WORDPRESS_BASE_URL + wordpressRoutes.PRIVACY_POLICY },
        { text: 'Terms & Conditions', link: WORDPRESS_BASE_URL + wordpressRoutes.PRIVACY_POLICY },
      ],
    },
  ],
  logoSection: [{ logo: mastercardLogoWhite }, { logo: visaLogoWhite }, { logo: amexLogoWhite }],
};

export const checkAccuracyWarning =
  'This invoice is calculated based on provided prices and quantities. Ensure to review and confirm the accuracy of all charges';

export const contactSalesMessage =
  'Since your guest count exceeds 150 a specialist will assist you further. Confirm to proceed. Note: An additional service fee applies.';

export const contactSalesMessageForContactClient =
  'As you are a contracted client a specialist will assist you further. Confirm to proceed.';

export const description = "It's looking a bit empty here – let's fill it up with some delicious dishes!";

export const SERVICE_TYPE_PARAM = 'id';
export const DEFAULT_SERVICE_TYPE = 'buffet';
export const DROP_OFF = 'drop-off';
export const BUFFET = 'buffet';
export const BUFFET_FULL_NAME = 'Full Service Buffet Catering';
export const DROP_OFF_FULL_NAME = 'Drop-off Catering';
export const ADDONS_CATEGORY = 'Add-On';

export const fullServiceMessageHome =
  "Your default menu is all set, but feel free to tweak it! Remember, you've got a full 3 days before the event to make any changes or cancel, no stress.";

export const deliveryMessageHome =
  'Our team will assist for 4 hours and depart an hour post-event. Need them longer? Contact sales to extend their hours.';

export const SAVE_LATER_MODAL_DESC =
  'Are you sure you want to leave? Save your progress by providing your Mobile Number.';

export const SAVE_LATER_MODAL_EMAIL_DESC =
  'Are you sure you want to leave? Save your progress by providing your Email.';

export const SAVE_LATER_MODAL_HEADING = 'Heading Out?.';

export const GOOGLE_MAP_API_STATUS_CODE = 'OK';

export const paymentFailedMessage =
  'Your transaction could not be completed, but don’t worry, your order details have been saved. Please check your payment information and try again';

export const paymentViewOrderMessage =
  'Alternatively, you can login using your Mobile number or email to access the order under Order History.';

export const paymentFailedContactUsMessage =
  ' If the issue persists, feel free to contact us for further assistance. Thank you for your understanding.';

export {
  loggedInHeaderTabs,
  moreItems,
  serviceType,
  statusCodes,
  tabs,
  uaePhoneNumberRegex,
  UiRoutes,
  validCVVRegex,
  validEmail,
  validNameRegex,
};

export const getErrorMessage = (error: unknown) => {
  const axiosError = error as AxiosError<{ message?: string }>;

  const message = axiosError?.response?.data?.message ?? 'An unexpected error occurred. Please try again later.';

  console.error('An error occurred:', axiosError);

  return message;
};

export const orderListEditModalMessage =
  'Changing the order and package details will clear all the dish selections that you have made. Are you sure you want to continue?';

export const orderDetailsAlertMessage = 'Please select the package first!';

export const btnToColorMapper: Record<string, string> = {
  CONFIRMED: theme?.primaryColor?.aquamarine,
  COMPLETED: theme?.primaryColor?.denseGray,
  CANCELLED: theme?.primaryColor?.brandPaletteRed,
  REVIEWED: theme.primaryColor?.brandPaletteOrange,
  QUOTE: theme?.primaryColor?.lunarElegance,
  DRAFT: theme?.primaryColor?.lunarElegance,
  'ACTION REQUIRED': theme.primaryColor.lightYellow,
};

export const paymentToColorMapper: Record<string, string> = {
  PENDING: theme?.primaryColor?.brandPaletteRed,
  FULLY_PAID: theme?.primaryColor?.aquamarine,
  REFUNDED: theme.primaryColor?.brandPaletteOrange,
  CREDIT_NOTED: theme?.primaryColor?.denseGray,
  PARTIAL_REFUNDED: theme?.primaryColor?.lunarElegance,
  FAILED: theme.primaryColor.lightYellow,
};

export type StatusType = 'CONFIRMED' | 'COMPLETED' | 'CANCELLED' | 'REVIEWED' | 'QUOTE' | 'DRAFT';

export const stepperStatus = ['CONFIRMED', 'COMPLETED', 'CANCELLED'];

export const orderStatusList = [
  {
    name: 'Confirmed',
    id: 'CONFIRMED',
  },
  {
    name: 'Completed',
    id: 'COMPLETED',
  },
  {
    name: 'Cancelled',
    id: 'CANCELLED',
  },
  {
    name: 'Reviewed',
    id: 'REVIEWED',
  },
  {
    name: 'Quote',
    id: 'QUOTE',
  },
  {
    name: 'Draft',
    id: 'DRAFT',
  },
  {
    name: 'Action Required',
    id: 'ACTION REQUIRED',
  },
];

export const paymentStatusList = [
  {
    name: 'Pending',
    id: 'PENDING',
  },
  {
    name: 'Fully Paid',
    id: 'FULLY_PAID',
  },
  {
    name: 'Refunded',
    id: 'REFUNDED',
  },
  {
    name: 'Credit Noted',
    id: 'CREDIT_NOTED',
  },
  {
    name: 'Partially Refunded',
    id: 'PARTIALLY_REFUNDED',
  },
  {
    name: 'Failed',
    id: 'FAILED',
  },
];

export const errorMessages = {
  eventNotFound: 'Event not found.',
};

export const numberOfPaxMessage = {
  orderNumberMessage: 'Order Confirmation Number:',
  successMessage:
    'Your catering order has been successfully submitted. An email has been sent with the order details to your email: :',
  extraMessage: {
    heading: 'What happens next:',
    messages: [
      'Please check your inbox for our confirmation email, which includes all the details of your order.',
      'Our team will now update your order with the applicable Service Fee/ Delivery Fee. Once this update is complete, you will receive an email notification with the final details and next steps.',
      'To make changes to your menu, please visit our website and log in using your email or phone number.',
    ],
  },
};

export const paymentSuccessMessages = (thresholdDays: number) => ({
  orderNumberMessage: 'Order Confirmation Number:',
  successMessage:
    'Your catering order has been successfully placed. We have sent a detailed confirmation to your email:',
  extraMessage: {
    heading: 'What happens next?',
    messages: [
      'Please check your inbox for our confirmation email, which includes all the details of your order.',
      ` We have noted your selected menu and event date. Remember, you can update your menu selections up to ${thresholdDays} days before your event.`,
      'To make changes to your menu, please visit our website and log in using your email or phone number.',
    ],
  },
});

export const quoteWithoutPaymentMessage = {
  orderNumberMessage: 'Order Confirmation Number:',
  successMessage: 'Your catering order has been submitted. We’re just one step away from finalizing your order.',
  extraMessage: {
    heading: 'Next Steps:',
    messages: [
      'Please check your inbox for our confirmation email, which includes all the details of your order.',
      'Please send your Purchase Order to hello@blastcatering.com to confirm your order. Once we receive your Purchase Order, we’ll proceed with the final preparations to ensure your event is a success.',
      'To make changes to your menu, please visit our website and log in using your email or phone number.',
    ],
  },
};

export const contractClientMessage = {
  orderNumberMessage: 'Order Confirmation Number:',
  successMessage:
    'Your catering order has been successfully submitted. An email has been sent with the order details to your email: :',
  extraMessage: {
    heading: 'What happens Next',
    messages: [
      'Please check your inbox for our confirmation email, which includes all the details of your order.',
      'Our team will now review and confirm your order. Once this done, you will receive an email notification with the final details and next steps.',
      'To make changes to your menu, please visit our website and log in using your email or phone number.',
    ],
  },
};

export const EDIT_MESSAGE = 'Click here to modify your meal selections for the day.';
export const ADD_MESSAGE = 'Click here to add another instance of the same service to your order.';
export const DELETE_MESSAGE =
  'Click here to remove this service from your order. Please note, this action cannot be undone.';
export const SERVICE_MESSAGE =
  'This fee covers staff, equipment, delivery, and all necessary services to ensure your event runs flawlessly.';

export const TIME_EXCEEDED_MESSAGE =
  "We're sorry, but orders cannot be confirmed online once they are close to the event date. Please contact our customer service team directly.";

export const cateringData = {
  buffet: {
    title: 'Full Service Buffet Catering',
    desc: 'Instant Quotes, Transparent Pricing, Exceptional Food and Personalized Service. Blast Catering provides an easy way to plan and execute your full service buffet catering. Offering a wide variety of international menu, experienced on-site service staff, everything considered to provide an end-to-end catering service solution in UAE.',
    tab: 'Looking for Drop-off Catering ?',
    packageModule: {
      title: ' Start Your Quote: Select Your Buffet Package ',
      desc: ' Explore our curated buffet catering packages and select the one that best suits your event needs. Don’t see the right combination of dishes? Start by viewing the menu for the day to create your own custom order.',
    },
    whatsIncluded: [
      'Experienced onsite service staff included.',
      'Food made from fresh, high quality ingredients.',
      'Diverse menu options refreshed daily.',
      'Stylish buffet setups and food displays.',
      'All necessary equipment, plates, and cutlery included.',
      'Complete setup and cleanup services.',
      'Professional support team available for assistance and queries.',
      'Access to a client dashboard to view order history and user feedback.',
    ],
    photoGallery: {
      label: 'Photo Gallery',
      title: 'Glimpses of Full Service Buffet Catering',
      images: [
        { name: 'Potato Gratin', image: dishFS1 },
        { name: 'Rocky Road Bites', image: dishFS2 },
        { name: 'Selection of Bakery Bites', image: dishFS3 },
        { name: 'Full Service Buffet Setup', image: dishFS4 },
        { name: 'Fennel, Feta and Pomegranate Salad', image: dishFS5 },
        { name: 'Rasberry Tart', image: dishFS6 },
        { name: 'Salad Bar', image: dishFS7 },
        { name: 'Full Service Buffet Setup', image: dishFS8 },
        { name: 'Buffet Setup for Office Lunch', image: dishFS9 },
        { name: 'Bakery Bites', image: dishFS10 },
        { name: 'Vegan Wrap', image: dishFS11 },
        { name: 'Smoked BBQ Chicken', image: dishFS12 },
      ],
    },
    howtoOrder: [
      {
        image: choosePackage,
        heading: 'Choose Your Package',
        content:
          "Select from a variety of buffet catering packages tailored for different occasions and times of day, or create your own if our standard offerings don't meet your needs.",
      },
      {
        image: secureCheckout,
        heading: 'Customize Your Menu',
        content:
          'Enhance your menu selection by swapping out dishes, adding optional beverages and making adjustments up to four days before your event. ',
      },
      {
        image: customizeMenu,
        heading: 'Confirm Your Order',
        content:
          'Review your order details and complete your purchase with our secure checkout process. Once confirmed, relax and look forward to enjoying our world-class service and delicious food.',
      },
    ],
  },

  dropOff: {
    title: 'Drop-off Catering',
    desc: 'Blast Catering is the leading provider of high quality drop-off catering service in Dubai. From corporate lunches, office gatherings, to any events  - we deliver for all your catering requirements.',
    tab: 'Looking for Full Service Buffet Catering ?',
    packageModule: {
      title: 'Start Your Drop-Off Catering Order',
      desc: "Click 'Customize Now' to start selecting the dishes you want from our diverse daily menu. Prefer a ready-made option? Choose from our standard catering packages for a seamless experience.",
    },
    whatsIncluded: [
      'Food made from fresh, high-quality ingredients.',
      'Diverse menu options refreshed daily.',
      'Food packed in environmentally friendly packaging.',
      'Delivery of food to your event location.',
      'Access to a client dashboard to view order history and user feedback.',
      'Professional support team available for assistance and queries.',
    ],
    photoGallery: {
      label: 'Photo Gallery',
      title: 'Glimpses of Drop-Off Catering',
      images: [
        { name: 'Sports Event Catering', image: dishDC1 },
        { name: 'Harissa Parmentine Potatoes', image: dishDC2 },
        { name: 'Breakfast Drop-off', image: dishDC3 },
        { name: 'Drop-off Catering for lunch Meeting', image: dishDC4 },
        { name: 'Lasagna', image: dishDC5 },
        { name: 'Chicken with Whole Grain Mustard', image: dishDC6 },
      ],
    },
    howtoOrder: [
      {
        image: choosePackage,
        heading: 'Choose Your Package',
        content:
          'Create your own custom order, or select from a variety of catering packages tailored for different occasions.',
      },
      {
        image: secureCheckout,
        heading: 'Customize Your Menu',
        content:
          'Enhance your menu selection by swapping out dishes, adding optional beverages, and making adjustments up to four days before your event.',
      },
      {
        image: customizeMenu,
        heading: 'Confirm Your Order',
        content:
          'Review your order details and complete your purchase with our secure checkout process. Our team prepares and delivers your food, ensuring the utmost quality.',
      },
    ],
  },
};

export const termsAndConditions = [
  'A 100% deposit is required to confirm your event booking. Alternatively, a valid Purchase Order can be submitted to secure the event date.',
  'All menu choices must be confirmed at least 2 business days prior to the event. This allows us to ensure the availability and quality of the items selected.',
  'If the event is canceled more than 2 business days before the scheduled event date for any reason, including government bans on events, the entire 100% deposit will be refundable.',
  'Any cancellation made less than 2 business days prior to the event date will result in the forfeiture of the entire 100% deposit.',
];

export const buffetWhatsIncluded = [
  'Experienced onsite service staff included',
  'Diverse menu options refreshed daily.',
  'All necessary equipment, plates, and cutlery included.',
  'Professional support team available for assistance and queries',
  'Food made from fresh high quality ingredients.',
  'Stylish buffet setups and food displays.',
  'Complete setup and cleanup services.',
  'Access to a client dashboard to view order history and user feedback',
];

export const dropOffWhatsIncluded = [
  'Food made from fresh, high-quality ingredients.',
  'Diverse menu options refreshed daily.',
  'Food packed in environmentally friendly packaging.',
  'Delivery of food to your event location.',
  'Access to a client dashboard to view order history and user feedback.',
  'Professional support team available for assistance and queries.',
];

export const sampleMenuLinks = [
  WORDPRESS_BASE_URL + '/menu/?tab=bargain',
  WORDPRESS_BASE_URL + '/menu/?tab=balanced',
  WORDPRESS_BASE_URL + '/menu/?tab=bountiful',
];
