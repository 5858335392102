import { styled } from 'styled-components';

import { Breakpoints } from '@src/styles/breakpoints';
import theme from '@src/styles/theme';

export const StyledBanner = styled.div`
  width: auto;
  background-color: ${theme?.primaryColor?.GlacierMist};
  color: ${theme?.primaryColor?.duskHarbor};
  padding: 1rem;
  text-align: center;
  line-height: 150%;
  border-radius: 4px;
  padding-right: 3rem;
  position: relative;
  // overflow: clip;
`;

export const PromoCode = styled.span`
  font-weight: bold;
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  margin: 0 0.25rem;
  border: 1px dashed #255573;
  position: relative;
`;

export const FAQLink = styled.a`
  text-decoration: underline;
  cursor: pointer;
  color: inherit;
`;

export const CloseBannerButton = styled.button`
  position: absolute;
  top: 14px;
  right: 20px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: ${theme?.primaryColor?.duskHarbor};
`;

export const PromoTab = styled.div`
  position: fixed;
  top: 50%;
  right: 0;
  background-color: ${theme?.primaryColor?.blastYellow};
  transform: translateY(-50%);
  color: ${theme?.primaryColor?.duskHarbor};
  padding: 10px 20px;
  cursor: pointer;
  font-weight: bold;
  box-shadow: -2px 2px 6px rgba(0, 0, 0, 0.1);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

  ${Breakpoints.MOBILE} {
    display: none;
  }
`;

export const MobileBannerText = styled.div`
  ${Breakpoints.DESKTOP_SMALL} {
    display: none;
  }

  ${Breakpoints.TABLET} {
    display: block;
  }

  ${Breakpoints.MOBILE} {
    display: block;
  }
`;

export const DesktopBannerText = styled.div`
  ${Breakpoints.MOBILE} {
    display: none;
  }

  ${Breakpoints.DESKTOP_SMALL} {
    display: block;
  }
`;

export const Tooltip = styled.div`
  position: absolute;
  bottom: -2.5rem;
  right: 10px;
  background-color: #f6fff9;
  border: 1px solid #48c1b5;
  color: ${theme?.primaryColor?.duskHarbor};
  padding: 5px 10px;
  border-radius: 5px;
  z-index: 2;
`;
