import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Logout } from '@mui/icons-material';
import { ListItemIcon, Menu, MenuItem } from '@mui/material';

import { UiRoutes } from '@src/lib/constants';
import { arrowDownIcon, arrowUpIcon } from '@src/lib/imgUrl';
import { clearEventDetails } from '@src/redux/action/eventDetails';
import { clearInvoiceDetails } from '@src/redux/action/invoiceDetails';
import { clearJourney } from '@src/redux/action/journeyStatus';
import { clearUserData, logoutAction } from '@src/redux/action/login';
import { LoginStateProps, ReduxProps } from '@src/redux/type';

import DeleteModal from '../DeleteModal';

import {
  DefaultProfileIcon,
  StyledProfileInfo,
  StyledProfileNameContainer,
  StyledUserName,
  StyledUserRole,
  StyleProfileContainer,
} from './styles';

const ProfileComponent = ({ isMobileView }: { isMobileView?: boolean }) => {
  const {
    firstName: userFirstName,
    lastName: userLastName,
    role: userRole,
  } = useSelector((state: { loginReducer: { loginState: LoginStateProps } }) => state?.loginReducer?.loginState);

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { inOrderJourney } = useSelector((state: ReduxProps) => state?.journeyStatusReducer);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const performLogoutActions = () => {
    dispatch(clearEventDetails());
    dispatch(logoutAction());
    dispatch(clearUserData());
    dispatch(clearInvoiceDetails());
    dispatch(clearJourney());
  };

  const handleLogout = () => {
    if (inOrderJourney) {
      setShowModal(true);
    } else {
      performLogoutActions();
      // navigate(UiRoutes.HOME);
      navigate(UiRoutes.PACKAGESFS);
    }
  };

  const handleEditModalClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <StyleProfileContainer onClick={e => handleClick(e)} $mobileView={isMobileView}>
        <StyledProfileInfo>
          <DefaultProfileIcon>{userFirstName?.charAt(0) ?? 'G'}</DefaultProfileIcon>
          <StyledProfileNameContainer>
            <StyledUserName>{`${userFirstName || ''} ${userLastName || ''}`.trim() || 'Guest'} </StyledUserName>
            <StyledUserRole>{userRole}</StyledUserRole>
          </StyledProfileNameContainer>
        </StyledProfileInfo>
        {anchorEl ? <img src={arrowUpIcon} alt='close icon' /> : <img src={arrowDownIcon} alt='open icon' />}
      </StyleProfileContainer>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => handleLogout()}>
          <ListItemIcon>
            <Logout fontSize='small' />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
      <DeleteModal
        isOpen={showModal}
        modalName='Are you sure you want to exit? Your progress will be saved.'
        onRequestClose={handleEditModalClose}
        onSave={() => {
          navigate(UiRoutes.ORDER_HISTORY);
        }}
        isLogoutAction={true}
        performLogoutActions={performLogoutActions}
      />
    </>
  );
};

export default ProfileComponent;
