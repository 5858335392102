import apiEndpoints from '@src/lib/apiEndpoints';
import { DataProps } from '@src/lib/getUpdate';

import apiRequest from './api';

export const updateUserDetails = (data: DataProps, id: string) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.UPDATE_CLIENT}/${id}`,
    data,
  });
};

export const createGuestUser = () => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.CREATE_GUEST_USER}`,
    data: {},
  });
};

export const getUserDetails = () => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.CURRENT_USER}`,
  });
};

export const updatePhoneNumberOTP = (data: { phoneNumber: string }) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.UPDATE_NUMBER_OTP}`,
    data,
  });
};

export const verifyPhoneNumberUpdateOTP = (data: { phoneNumber: string }, otp: string) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.VERIFY_UPDATED_NUMBER}`,
    data: { ...data, otp },
  });
};
