import actionConstants from '../constants';
import { InvoiceStateProps } from '../type';

const initialState: InvoiceStateProps = {
  subTotal: 0.0,
  serviceFee: 0.0,
  vat: 0.0,
  discountAmount: 0.0,
  finalTotal: 0.0,
  discount: 0,
  deliveryCharges: 0,
};

export default function invoiceDetailsReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionConstants.UPDATE_INVOICE_DETAILS:
      return {
        ...state,
        ...action?.payload,
      };
    case actionConstants.CLEAR_INVOICE_DETAILS:
      return initialState;
    default:
      return state;
  }
}
